.slick-slider {
    user-select: auto;
}
.home-banner-section{
	position: relative;
	padding-bottom: 110px;
	overflow: hidden;
	@include media-breakpoint-down(xl) {
		padding-bottom: 140px;
	}
	@include media-breakpoint-down(lg) {
		padding-bottom: 0;
	}
	.slick-list {
		overflow: visible;
	}
	.slick-next,
    .slick-prev {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.30);
		z-index: 1;
		border: 1px solid rgba(255, 255, 255, 0.40);
        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
		@include media-breakpoint-down(md) {
            width: 30px;
            height: 30px;
        }
        &:before{
			font-family: 'Font Awesome 5 Free';
			font-size: 30px;
			line-height: 1;
			opacity: 0.6;
			color: $white;
			font-weight: 900;
            @include media-breakpoint-down(lg) {
                font-size: 35px !important;
            }
			@include media-breakpoint-down(md) {
				font-size: 16px !important;
			}
        }
        &:focus, &:hover{
			background-color: rgba(255, 255, 255, 0.6);
			border: 1px solid rgba(255, 255, 255, 0.5);
            &:before{
                opacity: 1 !important; 
				color: $secondary;
            }
        }
        
    }
    
    .slick-prev{
        left: 60px !important;
        &:before {
            content: '\f104' !important;
        }
        @media only screen and (max-width:992px){
            left: 35px !important;
        }
        @media only screen and (max-width:800px){
            left: 30px !important;
        }
        @media only screen and (max-width:600px){
            left: 5px !important;
        }
    }
    
    .slick-next{
        right: 60px !important;
        &:before {
            content: '\f105' !important;
        }
        @media only screen and (max-width:992px){
            right: 35px !important;
        }
        @media only screen and (max-width:800px){
            right: 30px !important;
        }
        @media only screen and (max-width:600px){
            right: 5px !important;
        }
    }
	.banner-slide{
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		img{
			width: 100%;
			height: auto;
		}
		.banner-content{
			display: flex;
			position: relative;
			.banner-content-right {
				position: absolute;
				right: -233px;
				top: -60px;
				z-index: 1;	
				//opacity: 0;	
			//	transition: all 0.5s ease;	
				img{
					width: 820px;
					//opacity: 0;
				//	transition: all 1s ease;
				}
				
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
			.banner-content-left{
				max-width: 500px;
				width: 100%;
				opacity: 0;
				@include media-breakpoint-down(lg) {
					max-width: 85%;
					text-align: center;
					z-index: 1;
					position: relative;
					margin: 0 auto;
				}
				.sub-heading{
					font-size:0.88rem;
					line-height:1.2;
					font-weight: bold;
					display: block;
					margin-bottom: 12px;
					color: $primary;
					text-transform: uppercase;
					@include media-breakpoint-down(lg) {
						font-size: 0.75rem;
						margin-bottom: 5px;
					}
				}
				h1{
					max-width: 500px;
					width: 100%;
					margin-bottom: 32px;
					color: $white;
					@include media-breakpoint-down(lg) {
						max-width: 100%;
						margin-bottom: 30px;
					}
				}
				p{
					max-width: 428px;
					width: 100%;
					color: $white;
					font-size: 1.13rem; //18px
					line-height: 2;
					@include media-breakpoint-only(lg) {
						max-width: 380px;
					}
					@include media-breakpoint-down(lg) {
						max-width: 100%;
					}
					@include media-breakpoint-down(md) {
						font-size: 1rem;
					}
				}
				.banner-btn{
					margin-top: 60px;
					@include media-breakpoint-down(lg) {
						margin-top: 30px;
					}
				}
			}
		}
		.container{
			position: relative;
			z-index: 2;
		}
		&.slick-active{
            .banner-content-left{
				animation: fadeInUpContent 2s ease forwards;
			}
			.banner-content-right{
				.zoom-in-animation {
					opacity: 1;
    				transform: translateZ(0) scale(1);
				}
			}
			
        }
	}
}
.about-us-section{
	//margin-top:  85px;
	margin-bottom: 100px;
	@include media-breakpoint-down(lg) {
		margin-top:  40px;
		margin-bottom: 50px;
	}
	.about-us-content-block{
		border: 5px solid $light-gray;
		border-radius:10px;
		padding:40px;
		@include media-breakpoint-down(lg) {
		//	border-radius:30px;
			padding:24px;
		}
		.about-img-col{
			@include media-breakpoint-down(lg) {
				margin-bottom: 30px;
			}
			.img-box{
				position: relative;
				&:after {
					position: absolute;
					content: "";
					background-color: $black;
					opacity: 0.5;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius:10px;
				}
				.video-play-box{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 1;
					width: 100%;
					h5{
						color: $white;
						font-style: italic;
						text-align: center;
					}
					.play-button {
						background-color: $white;
						width: 70px;
						height: 70px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						margin: 0 auto 10px;
						transition: all 0.3s ease-in-out;
						i{
							font-size: 24px;
							margin-left: 4px;
							color: $secondary;
							transition: all 0.3s ease-in-out;
						}
						&:hover{
							background-color: $primary;
							i{
								color: $white;
							}
						}
					}
				}
			}
			img{
				border-radius: 10px;
				width: 100%;
			}
		}

		.about-content-col{
			padding-left: 65px;
			@include media-breakpoint-down(lg) {
				padding-left: 15px;
			}
			.content-sm{
				@include media-breakpoint-down(lg) {
					text-align: center;
				}
				h2{
					margin-bottom: 25px;
					@include media-breakpoint-down(xl) {
						margin-bottom: 20px;
					}
					@include media-breakpoint-down(lg) {
						margin-bottom: 15px;
					}
				}
				.content-btn{
					margin-top: 25px;
				}
			}
		}
		
	}
	.video-block-container{
		padding:0 40px;
		@include media-breakpoint-down(lg) {
			padding:0;
		}
		.video-block {
			margin-top: -122px;
			display: flex;
			align-items: center;
			background-color: $light-gray;
			border-radius: 10px;
			position: relative;
			//margin-bottom: -150px;
			@include media-breakpoint-down(lg) {
				flex-wrap: wrap;
				margin-top: 30px;
			}
			img{
				border-radius: 10px;
			}
			.video-block-overlay{
				position: absolute;
				background-color: rgba(1, 58, 98, 0.8);
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				padding:50px;
				line-height:1.4;
				text-align: center;
				h3{
					color: $white;
					max-width: 700px;
					margin: 0 auto 50px;
					font-weight: 400;
					line-height: 1.4;
				}
				.play-button{
					border: 3px solid $white;
					border-radius: 50%;
					padding: 15px;
					width: 80px;
					height: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					line-height: 1;
					cursor: pointer;
					i{
						color: $white;
						font-size: 35px;
						margin-left: 5px;
					}
				}
				
			}
		}
	}
}

///
.home-products-section{
	padding: 100px 0 0;
    background-color: $light-gray;
	@include media-breakpoint-down(lg) {
		padding: 50px 0 0;
	}
	.section-heading{
		max-width: 800px;
		margin: 0 auto 70px;
		@include media-breakpoint-down(lg) {
			margin: 0 auto 40px;
		}
	}
	.product-content-block{
		background-position: center; 
		background-repeat: no-repeat; 
		background-size: cover;
		padding: 30px;
		height: 100%;
		width: 100%;
		@include media-breakpoint-down(md) {
			padding: 20px;
		}
		.product-inner-block {
			background-color: rgba(0, 57, 93, 0.9);
			height: 100%;
			display: flex;
			align-items: center;
			padding: 50px;
			color: $white;
			border-radius: 10px;
			@include media-breakpoint-down(md) {
				padding: 20px;
			}
			.content-sm{
				h3{
					color: $white;
					margin-bottom: 20px;
				}
				color: $white;
				max-width:600px;
			}
			.btn-red{
				&:hover, &:focus, &:active{
					border-color:$white !important;
					background-color: $white !important;
					color: $secondary !important;
				}
			}
		}
	}
	.product-img-col{
		img{
			width:100%;
			height:auto;

		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	.left-content{
		.product-content-block{
			.product-inner-block {
				.content-sm{
					margin-left: auto;
					text-align: right;
					@include media-breakpoint-down(lg) {
						text-align: left;
					}
				}
			}
		}
	}
	.left-img{
		.product-img-col{
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			@include media-breakpoint-down(lg) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			img{
				border-top-right-radius: 10px;
    			border-bottom-right-radius: 10px;
				@include media-breakpoint-down(lg) {
					border-top-right-radius: 0;
    				border-bottom-right-radius: 0;
				}
			}
		}
		.product-content-col{
			.product-content-block{
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
				@include media-breakpoint-down(lg) {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
	.right-img{
		.product-img-col{
			border-top-left-radius: 10px;
			@include media-breakpoint-down(lg) {
				border-top-left-radius: 0;
			}
			img{
				border-top-left-radius: 10px;
				@include media-breakpoint-down(lg) {
					border-top-left-radius: 0;
				}
			}
		}
		.product-content-col{
			.product-content-block{
				border-top-right-radius: 10px;
				@include media-breakpoint-down(lg) {
					border-top-right-radius: 0;
				}
			}
		}
	}
}

.product-finder-section{
	padding: 150px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
	@include media-breakpoint-down(lg) {
		padding: 50px 0;
	}
	.product-finder--box {
		padding: 50px;
		//background-color: rgba(245, 245, 245, 0.4);
		background-color: $white;
		//backdrop-filter: blur(5px);
		//-webkit-backdrop-filter: blur(5px);
		border-radius: 10px;
		border: 1.5px solid rgba(255, 255, 255, 0.18);
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
		@include media-breakpoint-down(lg) {
			padding: 30px;
		}
		.form-label{
			font-size: 1rem;
			line-height:1.9;
			font-weight: $medium;
			margin-bottom: 2px;
			color: $grey-semi-dark;
		}
		.form-control{
			border-radius: 10px;
			border: 2px solid $grey-300;
		}
		.input-col{
			margin-bottom: 15px;
		}
	}
}

.news-event-section{
	.news-event-heading{
		padding: 100px 0 200px;
		background-color:$light-gray;
		@include media-breakpoint-down(lg) {
			padding: 50px 0 200px;
		}
		@include media-breakpoint-down(md) {
			padding: 50px 0 150px;
		}
		.heading-row{
			display:flex;
			align-items:center;
			@include media-breakpoint-down(lg) {
				flex-wrap: wrap;
			}
			.heading-title {
				padding-right: 30px;
				position: relative;
				@include media-breakpoint-down(md) {
					padding-right: 15px;
				}
				&::after{
					content: '';
					position: absolute;
					top: 50%;
					transform:translateY(-50%);
					right: 0;
					height:calc(100% - 20px);
					width: 1px;
					background-color: $secondary;
				}
			}
			.heading-desc{
				padding: 0 30px;
				max-width: 500px;
				width: 100%;
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}
			.heading-btn{
				@include media-breakpoint-down(lg) {
					margin-left: 30px;
				}
				@include media-breakpoint-down(md) {
					margin-left: 15px;
				}
			}
		}
	}
	.news-section{
		margin-top: -140px;
		padding-bottom: 100px;
		@include media-breakpoint-down(lg) {
			padding-bottom: 50px;
		}
		@include media-breakpoint-down(md) {
			margin-top: -120px;
		}
		.news-row{
			margin-bottom: -30px;
			.news-col{
				margin-bottom: 30px;
			}
		}
	}
}


.singel-news{
	.news-img{
		margin-bottom: 20px;
		border-radius: 8px;
		width: 100%;
		overflow: hidden;
		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
		img{
			border-radius: 10px;
			width: 100%;
			transition: all 1s ease-in-out;
		}
	}
	h4{
		margin-bottom: 20px;
		font-size:1.125rem; //20px 
		line-height:1.3;
		@include media-breakpoint-down(lg) {
			font-size:1.13rem;//18xx
			}
		a{
			color:$secondary;
			&:hover{
				color:$primary;
			}
		}
	}
	.news-date-author{
		margin-bottom: 20px;
	}
	.content-xs{
		margin-bottom:20px;
		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
	}
	&:hover{
		.news-img{
			img{
				transform: scale(1.2);
				transition: all 1s ease-in-out;
			}
		}
	}
}
.news-date-author{
	display: flex;
	align-items: center;
	margin: 0;
	padding: 0;
	list-style: none;
	li{
		font-size: 1.14rem;
		line-height: 1;
		font-weight: $medium;
		color:$secondary;
		position: relative;
		padding: 0 15px;
		@include media-breakpoint-down(md) {
			font-size: 12px;
			padding: 0 8px;
		}
		&:first-child{
			padding-left: 0;
			&:after {
				content: none;
			}
		}
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			display: inline-block;
			background-color:$secondary;
			width: 1px;
			height: 15px;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
}
.link-umderline{
	font-size: 1rem;
	line-height:1;
	text-transform: uppercase;
	border-bottom: 2px solid $secondary;
	font-weight: $bold;
	padding-bottom: 1px;
	position: relative;
	color: $primary;
	&:before, &:after {
		content: "";
		width: 3px;
		height: 2px;
		bottom: -1px;
		right: 0;
		left: 0;
		margin: 0 auto;
		position: absolute;
		z-index: -1;
		transition: all 0.6s ease 0s;
	}
	&:hover{
		border-bottom: 2px solid transparent;
		color: $secondary;
		&:before, &:after{
			width: 100%;
			outline: 0;
			background-color: $primary;
			transition: all 0.6s ease 0s;
		}
	}
}


/* Segment Section */
.segments-section{
	padding:100px 0;
	background-repeat: no-repeat;
    background-size: auto;
    background-position: center top 50px;
	@include media-breakpoint-down(lg) {
		padding:50px 0;
	}
	.section-heading{
		max-width: 800px;
		margin: 0 auto 70px;
		@include media-breakpoint-down(lg) {
			margin: 0 auto 40px;
		}
	}
	.segment-row{
		margin-bottom: -30px;
		.segment-col{
			margin-bottom: 30px;
		}
	}
	.segment-box{
		border: 2px solid $secondary;
		padding: 40px 25px;
		display: block;
		text-decoration: none;
		text-align: center;
		border-radius: 10px;
		position: relative;
		.segment-box-inner-overlay{
			transition: opacity 400ms ease-in, margin 250ms ease;
			border: 1px solid transparent;
			margin: 0;
			padding: 10px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: auto;
			height: auto;
			display: block;
			border-radius: 10px;
			opacity: 0;
			padding: 15px;
			background-color: rgba(227, 30, 37, 0.05);
		}
		.segment-icon{
			width:100px;
			height:100px;
			background-color: $light-gray;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto 20px;
			border-radius: 10px;
			position: relative;
			z-index: 1;
			transition: opacity 400ms ease-in, margin 250ms ease;
			img{
				max-width:100%;
			}
		}
		h5{
			position: relative;
			z-index: 1;
		}
		&:hover{
			.segment-box-inner-overlay{
				border: 1px solid $primary;
				margin: 15px;
				opacity: 1;
			}
			.segment-icon{
				background-color: $white;
				transition: opacity 400ms ease-in, margin 250ms ease;
			}
		}
	}
}

/* The animation starts here */
.animated-box {
  position: relative;
}

.animated-box:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background: linear-gradient(120deg, #00F260, #0575E6, #00F260);
  background-size: 300% 300%;
  clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}

.animated-box.in:after {
  animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite;
}

/* motion */
@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
  }
  75% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
  }
  100% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
  }
}
