
$default-font: 'Montserrat', sans-serif;

$red: #e31e25;
$grey-dark: #003c5e;  
$grey-semi-dark: #565656;
$grey-dart-100: #121212;
$light-gray: #F5F5F5; //
$grey-100: #C4C4C4;
$grey-200: #898989;
$grey-300: #EEEEEE;
$white: #ffffff;
$black: #000000;
$orange: #F0A523;
$green-second: #21D36E;
//custom color End
$accordion-icon-color: $white;


$primary:       $red !default;//
$secondary:     $grey-dark !default;//
$light:         $white !default;//
$dark:          $black !default; //


$large-xl-container: "(min-width : 1790px)";
$large-md-container: "(min-width : 1600px)";
$desktop-md: "(min-width:1367px) and (max-width : 1599px)";
$laptop: "(min-width:1200px) and (max-width : 1366px)";
$laptop-medium: "(min-width:1301px) and (max-width : 1400px)";
$tablet-large: "(min-width:1024px) and (max-width : 1199px)";
$tablet-medium: "(min-width:992px) and (max-width : 1023px)";
$iphone6: "(max-width : 380px)";
$iphone5: "(max-width : 374px)";
$xxl: "(min-width:1921px)";
$xxxl: "(min-width:3000px)";



$lighter:         lighter !default;
$light:           300 !default;
$normal:          400 !default;
$medium:          500 !default;
$semibold:        600 !default;
$bold:            700 !default;
$bolder:          bolder !default;

