/*Button START*/
.btn{
	padding: 16px 20px 14px;
    font-family: $default-font;
    font-weight: $medium !important;
    font-size: 1rem !important;
    line-height:1 !important;
    text-transform: uppercase;
	display: inline-block;
	text-align: center;
	@include transition(all 0.3s ease-in-out);
	text-decoration: none !important;
    border-radius: 10px;
    border: 2px solid;
    position: relative;
    letter-spacing: 1px;
	&:hover, &:focus{
		text-decoration: none;
		outline: none;
		box-shadow: none;
        border: 2px solid;
    }
    @include media-breakpoint-down(lg){
        padding: 9px 14px;
        font-size: 0.88rem !important;
        border-radius: 7px;
    }
}


.btn-red{
    border-color:$primary !important;
    background-color: $primary !important;
    color: $white !important;
    &:hover, &:focus, &:active{
        border-color:$secondary !important;
        background-color: $secondary !important;
        color: $white !important;
    }
}
.btn-grey{
    border-color:$secondary !important;
    background-color: $secondary !important;
    color: $white !important;
    &:hover, &:focus, &:active{
        border-color:$primary !important;
        background-color: $primary !important;
        color: $white !important;
    }
}
.btn-white-border{
    border-color:$white !important;
	border: 2px solid;
    background-color: transparent !important;
    color: $white !important;
    &:hover, &:focus, &:active{
        border-color:$primary !important;
        background-color: $primary !important;
        color: $white !important;
    }
}
