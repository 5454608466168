/* Frameworks */
@import 'common/custom-variables.scss';
@import 'frameworks/bootstrap/bootstrap.scss';
@import 'frameworks/fontawesome';
@import 'frameworks/slick';
@import 'frameworks/slick-theme';

/* Font Face */
//@import 'common/font';

/* Variables */


/* Common Section */
@import 'common/mixin';
@import 'common/common';
@import 'common/header';
@import 'common/button';
@import 'common/footer';
@import 'common/home';
@import 'common/about';


/* pages */