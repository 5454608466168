html {
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100%;
	font-size: 16px;
}
body {
	font-family: $default-font; 
	font-weight: $normal;
	color: $grey-semi-dark;
	font-size: 16px;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
}
a{
	cursor: pointer;
	color: #000;
	@include transaction(all 0.5s ease-in-out);	
	&:hover{
		text-decoration: none;
		color: $grey-semi-dark;
	}
	&:focus{
		border-bottom: 1px solid $primary;
	}
}
h1, h2, h3, h4, h5, h6{
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: $bold;
	color: $secondary;
}
h1{
	font-size: 3.5rem; //56px
	line-height: 1.2;
	@include media-breakpoint-down(xl) {
		font-size: 2.63rem; //42px
	}
	@include media-breakpoint-down(lg) {
		font-size: 2.25rem; //36px
	}
	@include media-breakpoint-down(md) {
		font-size: 1.75rem; //28px
	}
}
h2{
	font-size: 2.5rem; //40px
	line-height: 1.2;
	@include media-breakpoint-down(xl) {
		font-size: 2.25rem; //36px
	}
	@include media-breakpoint-down(lg) {
		font-size: 2rem; //32px
	}
	@include media-breakpoint-down(md) {
	    //font-size: 1.75rem; //28px
		font-size: 1.5rem; //24px
	}
}
h3{
	font-size: 2rem; //32px
	line-height:1.2;
	@include media-breakpoint-down(xl) {
		font-size: 1.63rem; //26px
	}
	@include media-breakpoint-down(lg) {
		//font-size: 1.5rem; //24px
		font-size: 1.38rem;  //22
	}
}
h4{
	font-size: 1.5rem; //24px
	line-height:1.2;
	@include media-breakpoint-down(lg) {
	//	font-size: 1.38rem; //22px
		font-size:1.25rem; //20pxx
	}
}

h5{
	font-size: 1.38rem; //22px
	line-height:1.2;
	@include media-breakpoint-down(lg) {
		font-size: 1rem; //16px
	}
}

.container{
	@include media-breakpoint-up(xl){
		max-width: 1294px;
	}
	@include media-breakpoint-up(lg){
		max-width: 1294px;
	}
	@include media-breakpoint-up(md){
		max-width: 1294px;
	}
	@include media-breakpoint-up(sm){
		max-width: 1294px;
	}
}
img{
	max-width: 100%;
	height: auto;
}
*:focus {outline:none !important}
a{
	text-decoration: none;
	&:focus, &:hover{
		text-decoration: none;
		border-bottom: none;
		outline: none;
	}
}
.content-md{
	font-size:1.25rem; //20px
	line-height:1.6;
	font-weight: $normal;
	@include media-breakpoint-down(md){
		font-size:1.13rem; //18px
	}
	&:last-child {
		margin-bottom: 0;
	}
	p, a, li, span{
		font-size:1.25rem; //20pxx
		line-height:1.6; 
		font-weight: $normal;
		@include media-breakpoint-down(md){
			font-size:1.13rem; //18px
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a{
		color: $primary;
		
	}
}
.content-sm{
	font-size: 1.13rem; //18px
	line-height:1.9;
	font-weight: $normal;
	@include media-breakpoint-down(md){
		font-size: 1rem;
	}
	&:last-child {
		margin-bottom: 0;
	}
	// h2{
	// 	font-size: 3rem; //48px
	// 	line-height: 1.2;
	// 	@include media-breakpoint-down(lg) {
	// 		font-size: 2.25rem; //36px
	// 	}
	// 	@include media-breakpoint-down(md) {
	// 		font-size: 2rem; //32px
	// 	}
	// 	@include media-breakpoint-down(xs) {
	// 		font-size: 1.75rem; //28px
	// 	}
	// }
	p, a, li, span{
		font-size: 1.13rem; //18px
		line-height:1.9; 
		font-weight: $normal;
		@include media-breakpoint-down(md){
			font-size: 1rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a{
		color: $primary;
		
	}
}
.content-xs{
	font-size: 1rem;
	line-height:1.8;
	font-weight: $normal;
	&:last-child {
		margin-bottom: 0;
	}
	p, a, li, span{
		font-size: 1rem;
		line-height:1.8;
		font-family: $default-font;
		font-weight: $normal;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a{
		color: $primary;
		
	}
}

.form-control, .form-select {
    padding: 8px 15px;
    line-height: 1.2;
    color: $grey-dart-100;
    border: 1px solid $grey-200;
    height: 50px;
    border-radius: 10px;
	font-size: 0.88rem;
	@include media-breakpoint-down(md){
		height: 50px;
		padding: 10px 26px;
		font-size: 0.88rem;
	}
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $grey-200;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: $grey-200;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		color: $grey-200;
	}
	&:-moz-placeholder { /* Firefox 18- */
		color: $grey-200;
	}
}
.sub-title{
	font-size: 0.94rem !important; //15px
	line-height:1.5 !important;
	font-weight: $medium !important;
	text-transform: uppercase;
	letter-spacing:2px;
	color: $primary;
	display: inline-block;
    margin: 0 0 10px;
}
.section-heading{
	margin-bottom: 40px;
	@include media-breakpoint-down(md){
		margin-bottom: 30px;
	}
}

.page-header{
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	&:after { 
		content:'';
		position: absolute;
		background-color: #000000;
		opacity: 0.56;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	.header-bg{
		img{
			width: 100%;
			height: auto;
		}
	}
	.header-content-container{
		
		.header-content{
			padding: 200px 15px 24px;
			z-index: 1;
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			@include media-breakpoint-down(lg){
				padding: 150px 15px 24px;
			}
			@include media-breakpoint-down(md){
				padding: 95px 15px 24px;
			}
			.header-inner-content{
				width: 100%;
				max-width: 1200px;
				margin: 0 auto;
				text-align: center;
				color: $white;
				h1{
					color: $white;
					margin-bottom: 138px;
					@include media-breakpoint-down(lg){
						margin-bottom: 60px;
					}
					@include media-breakpoint-down(md){
						margin-bottom: 40px;
					}
				}
				.nav-breadcrumb{
					display: flex;
					align-items: center;
					justify-content: center;
					.breadcrumb-item{
						font-size: 0.88rem;
						line-height: 1;
						font-weight: $medium;
						text-transform: uppercase;
						letter-spacing:1px;
						&.active{
							color: $white;
							font-weight: $medium;
						}
						+ .breadcrumb-item{
							padding-left: 10px;
							&:before {
								float: left;
								padding-right: 10px;
								color: $white;
								content: '\f054';
								font-family: 'Font Awesome 5 Free';
								font-weight: 600;
								-webkit-font-smoothing: antialiased;
								// display: inline-block;
								font-style: normal;
								font-variant: normal;
								text-rendering: auto;
								line-height: 1;
							}
						}
					}
					a{
						color: $white;
						text-decoration: none;
						&:hover{
							color: $primary;
						}
					}
				}
			}
		}
	}
}





@-webkit-keyframes fadeInUpContent {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInUpContent {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
  
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

  @-webkit-keyframes fadeInRightCustom {
	from {
	  opacity: 0;
	  transform: translateX(300px);
	}
	to {
	  opacity: 1;
	}
  } 
  @keyframes fadeInRightCustom {
	from {
	  opacity: 0;
	  transform: translateX(300px);
	}
	to {
	  opacity: 1;
	}
  }




.zoom-in-animation {
	//transition-timing-function: cubic-bezier(.175,.885,.32,1.275);
    transform: scale(.6);
	transition-duration: 1s;
	opacity: 0;
    transition-property: opacity,transform;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}





