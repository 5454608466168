header{
	// position: absolute;
    // width: 100%;
    z-index: 999999;
    background-color: $white;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(lg){
		position: absolute;
		top: 45px;
	}
	&.fixed {
		top: 0;
		position: fixed;
		width: 100%;
		animation: fadeInDown 1s both;
		@include media-breakpoint-down(lg){
			animation: none;
		}
		@include media-breakpoint-up(lg){
			.navbar-brand {
				margin-top: 5px;
			}
		}
	}
	.header-top-bar{
		padding: 8px 0;
		background-color: $light-gray;
		.container{
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		.top-link{
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			list-style: none;
			margin-right: 15px;
			li{
				padding: 0 15px;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					right: 0;
					top: 50%;
    				transform: translateY(-50%);
					height: 14px;
					width:1px;
					background-color: $grey-semi-dark;
				}
				a{
					font-size:14px;
					line-height: 1;
					&:hover{
						color: $primary
					}
					i{
						margin-right: 5px;
					}
				}
			}
		}
	}
	.navbar-brand {
		padding-top: 0;
		padding-bottom: 0;
		margin-right: 1rem;
		background-color: $white;
		padding: 5px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
		border-radius: 50%;
		margin-top: 5px;
		margin-bottom: -20px;
		z-index: 99999;
		@include media-breakpoint-up(lg){
			margin-top: -20px;
		}
		img{
			height: 115px;
			@include media-breakpoint-down(lg){
				height: 80px;
			}
		}

	}
	.container{
		position: relative;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		flex-flow: row wrap;
		align-items: center;
		transition: ease height 300ms;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
	//	border-top: 5px solid #e2e2e2;
		box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
		// @include media-breakpoint-down(lg){
		// 	padding-top: 15px;
		// 	padding-bottom: 15px;
		// }
		.navbar-row {
			display: flex;
			flex-grow: 1;
			align-items: center;
			align-self: stretch;
			max-height: 100%;
		}
		.navbar-col {
			display: flex;
			-ms-flex-item-align: stretch;
			align-self: stretch;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			flex-grow: 1;
			-webkit-box-orient: vertical;
			flex-direction: column;
		}
	}
	.nav-item {
		padding: 0 14px;
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
		@include media-breakpoint-down(xl){
			padding: 0 3px;
		}
		@include media-breakpoint-down(lg){
			border-bottom: 1px solid rgba(255, 255, 255, 0.07%);
		}
		&.dropdown-mega{
			position: static;
		}
		&:hover{
			.nav-link {
				&:before, &:after{
					width: 100%;
					outline: 0;
					background-color: $primary;
					transition: all .6s ease 0s;
				}
			}
		}
	}
	.nav-link {
		font-size: 1rem;
		line-height: 1;
		font-weight: $medium;
		color: $secondary;
		border-radius: 5px;
	//	padding: 8px 5px !important;//
		padding: 10px 5px !important;
		position: relative;
		&:before, &:after  {
			content: "";
			width: 3px;
			height: 2px;
			bottom: 0;
			right: 0;
			left: 0;
			margin: 0 auto;
			position: absolute;
			z-index: 1;
			transition: all .6s ease 0s;
		}
		&.show{
			&:before, &:after{
				width: 100%;
				outline: 0;
				background-color: $primary;
				transition: all .6s ease 0s;
			}
			.arrow-sm-down {
				&:before {
					transform: translatey(-50%) rotate(-90deg);
					opacity: 0;
				}
				&:after {
					transform: translatey(-50%) rotate(0);
				}
			}
		}
		.arrow-sm-down {
			display: inline-block;
			width: 1rem;
			height: 1rem;
			border: 0.125rem solid;
			border-bottom: 0;
			border-left: 0;
			border-radius: 1px;
			text-indent: -624.9375rem;
			vertical-align: sub;
			margin-left: 4px;
			transform: rotate(135deg);
			width: 0.5rem;
			height: 0.5rem;
			@include media-breakpoint-down(lg){
				float: right;
				transform: none;
				position: relative;
				width: 12px;
				height: 12px;
				border: none;
				border-radius: 0;
				cursor: pointer;
				&:before , &:after {
					content: "";
					display: block;
					background-color:$white;
					position: absolute;		
					top: 50%;
					left: 0;
					transition: .35s;
					width: 100%;
					height: 2px;
				}
				&:before {		
					transform: translatey(-50%);
				}
				
				&:after {
					transform: translatey(-50%) rotate(90deg);
				}
			}

		}
		@include media-breakpoint-down(xl){
			padding: 8px 10px !important;
		}
		@include media-breakpoint-down(lg){
			padding: 15px 10px !important;
		}
	}
	@include media-breakpoint-up(lg){
		.navbar-collapse{
			position: relative;
		}
	}
	.navbar-right{
		ul{
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			li{
				padding: 0 14px;
				&:first-child{
					padding-left: 0;
				}
				&:last-child{
					padding-right: 0;
				}
				@include media-breakpoint-down(lg){
					padding: 0 10px;
				}
				a{
					color: $grey-semi-dark;
					font-size: 1.25rem; //20px
					&:hover{
						color:$primary;
					}
					@include media-breakpoint-down(lg){
						font-size: 1rem;
					}
				}
				&.header-buy-btn{
					padding-left: 46px;
					@include media-breakpoint-down(xl){
						padding-left: 30px;
					}
					@include media-breakpoint-down(lg){
						display: none;
					}
					
					.btn{
						padding: 11px 20px 9px;
					}
				}
			}
		}
	}
	.dropdown {
		.dropdown-menu {
			@include media-breakpoint-up(lg){
				top: 100%;
				top: -10000px;
				display: block;
				opacity: 0;
				left: auto;
				border: 0;
				-webkit-box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.7);
				box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.7);
				margin: 0;
				min-width: 200px;
				padding: 5px 0;
				text-align: left;
				right: 15px;
				width: auto;
				transition: transform .2s ease-out;
				transform: translate3d(0, 10px, 0);
			}
			@include media-breakpoint-down(lg){
				background-color: rgba(255, 255, 255, 0.20);
			}
			.dropdown-item {
				
				@include media-breakpoint-down(lg){
					color: $white;
					font-size: 0.75rem;
					&:hover, &:focus{
						color: $white;
						background-color:$primary;
					}
				}
			}
			li{
				margin-bottom: 5px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		&:hover{
			.dropdown-menu {
				@include media-breakpoint-up(lg){
					display: block;
					margin-top: 0; 
					display: block;
					opacity: 1;
					top: 100%;
					left: 0;
					transform: translate3d(0, 0, 0);
				}
			}
		}
	}
	.dropdown-menu-mega{
		@include media-breakpoint-down(lg){
			margin-top:0;
		}
		.dropdown-mega-menu-content{
			padding: 24px;
			@include media-breakpoint-down(lg){
				padding: 15px 5px;
			}
			.dropdown-mega-sub-title{
				font-size:1.2rem;
				font-weight:$semibold;
				color:$secondary;
				border-bottom: 2px solid $secondary;
				padding-bottom: 5px;
				display: block;
				margin-bottom: 10px;
				@include media-breakpoint-down(lg){
					font-size:1rem;
					color:$white;
					border-bottom: 2px solid $white;
				}
			}
			.dropdown-mega-sub-nav{
				padding: 0;
				margin: 0;
				list-style: none;
				li{
					margin-bottom: 5px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			.mega-menu-col{
				@include media-breakpoint-down(lg){
					margin-bottom: 20px;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	 
}
.navbar-toggler{
	padding: 0;
    line-height: 1;
    border: 0;
    border-radius: 0;
	padding-left: 15px;
	@include media-breakpoint-down(lg){
		padding-left: 10px;
	}
	&:focus{
		box-shadow: none;
		outline: 0;
	}
	.hamburger {
		z-index: 9999;
		position: relative;
		.line {
			width: 35px;
			height: 3px;
			background-color: $secondary;
			display: block;
			margin: 8px auto;
			transition: all 0.3s ease-in-out;
			@include media-breakpoint-down(lg){
				width: 20px;
				margin: 4px auto;
			}
		}
	}
}

body.offcanvas-active {
	overflow: hidden;
	.navbar-toggler{
		.hamburger{
			.line{
				background-color: $secondary;
				&:nth-child(1) {
					transform: translateY(16px) rotate(45deg);
					@include media-breakpoint-down(lg){
						transform: translateY(7px) rotate(45deg);
					}
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: translateY(-6px) rotate(-45deg);
					@include media-breakpoint-down(lg){
						transform: translateY(-7px) rotate(-45deg);
					}
				}
			}
		}
	}
}
@include media-breakpoint-down(lg){
.screen-overlay {
    width: 0%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(34, 34, 34, 0.6);
    transition: opacity 0.2s linear, visibility 0.1s, width 1s ease-in;
    &.show {
        transition: opacity 0.5s ease, width 0s;
        opacity: 1;
        width: 100%;
        visibility: visible;
      }
  }
}
@include media-breakpoint-down(lg){
    
    .header .collapse:not(.show) {
        display: inherit;
    }
    .navbar-dark{
        .navbar-nav{
            .nav-link{
                color: $secondary;
            }
        }
    }
    .mobile-offcanvas {
        visibility: hidden;
        transform: translateX(100%);
        border-radius: 0;
        //display: block;
        position: fixed;
        top: 75px;
        right: 0;
        height: 100%;
        z-index: 10001;
        width: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
        background-color:$secondary;
        padding: 20px 10px 30px;
        &.show {
            visibility: visible;
            transform: translateX(0);
        }
		.nav-link {
			color: $white;
			&.active, &:hover {
				background-color: transparent;
				color: $primary;
			}
		}
        .dropdown-menu{
            border: 0;
        }
    }
      .dropdown-item {
        white-space: normal;
    }
}

.social-link{
	margin: 0;
	padding: 0;
	list-style: none;
	display:flex;
	align-items: center;
	li{
		padding: 0 5px;
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
		}
		a{
			height:25px;
			width: 25px;
			display: flex;
			align-items: center;
			justify-content:center;
			background-color:$secondary;
			color: $white;
			border-radius: 50%;
			font-size: 13px;
			&:hover{
				background-color:$primary;
			}
		}
	}
}



@keyframes fadeInDown{
	0%{
		opacity:0;
		transform:translateY(-20px)
	}
	100%{
		opacity:1;
		transform:translateY(0)
	}
}