footer{
    background-color: #26272B;
    padding-top:65px;
    @include media-breakpoint-down(lg) {
        padding-top:35px;
    }
    .logo-social-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        border-bottom: 1px solid $grey-semi-dark;
    }
    .footer-logo{
        max-width: 300px;
        img{
            @include media-breakpoint-down(md) {
                width: 80%;
            }
        }
        a{
            text-decoration: none;
            color: $primary;
            font-size: 42px;
            line-height:1;
            font-weight: bold;
            &:hover{
                color: $primary;

            }
            @include media-breakpoint-down(lg) {
                font-size: 30px;
            }
        }
    }
    .footer-tagline{
        line-height:1.6;
        font-size: 1.5;
        color: $grey-200;
        font-weight: $medium;
        margin-right: 30%;
        margin-right: 20%;
        font-size: 22px;
        @include media-breakpoint-down(lg) {
            font-size: 18px;
        }
        @include media-breakpoint-down(md) {
            font-size: 1rem;
            margin-right: 0;
        }
    }
    .footer-newslatter{
        max-width: 322px;
        margin-top: 35px;
        @include media-breakpoint-down(lg) {
            max-width: 450px;
            margin-top: 25px;
        }
        .form-control {
            padding: 10px 20px;
            font-size: 14px;
            line-height: 1.5;
            height: 50px;
            border-radius: 25px;
        }
        .input-group .btn {
            position: relative;
            z-index: 2;
            background-color: $white;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            padding: 14px 18px;
            border: 1px solid $grey-200;
        }
    }
    .footer-content-row{
        padding: 50px 0;
        @include media-breakpoint-down(lg) {
            padding: 30px 0;
        }
        h5{
            font-size: 18px;
            line-height:1.5;
            font-weight: $semibold;
            color: $white;
            margin: 0 0 20px;
            @include media-breakpoint-down(lg) {
                margin: 0 0 15px;
            }
        }
        p{
            font-size: 1rem;
            line-height:1.5;
            color: $grey-200;
            font-weight: $normal;
            margin-bottom: 5px;
        }
        .icon-text{
            color: $white;
            font-weight: $medium;
            margin-bottom: 15px;
            &.address-text{
                margin-top: 20px;
            }
            i{
                color: $white;
                margin-right: 8px;
            }
            a{
                color: $white;
                text-decoration: none;
                &:hover{
                    color: $primary;
                }
            }
        }
        .footer-nav{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                padding-bottom: 10px;
                &:last-child {
                    padding-bottom: 0;
                }
                a{
                    font-size: 16px;
                    line-height:1.5;
                    color: $grey-200;
                    text-decoration: none;
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
        
    }
    .footer-newslatter-col{
        @include media-breakpoint-down(lg) {
            border-top: 1px solid $grey-semi-dark;
            padding-top: 25px;
            margin-top: 15px;
        }
    }
    .footer-copyright-row {
        padding: 36px 0;
        border-top: 1px solid $grey-semi-dark;
        display: flex;
        align-items:center;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
        }
        p{
            font-size: 14px;
            line-height:1.5;
            color: $grey-200;
            font-weight: $medium;
            margin: 0;
            img{
                vertical-align:middle;
                margin-right: 20px;
            }
            
            
        }
        .copyright-text{
            @include media-breakpoint-down(lg) {
                width: 100%;
                text-align:center;
                order: 2;
            }
        }
        .copyright-links{
            @include media-breakpoint-down(lg) {
                width: 100%;
                text-align:center;
                order: 1;
                margin-bottom: 20px;
            }
            p{
                a{
                    color: $grey-200;
                    text-decoration: none;
                    &:hover{
                        color: $primary; 
                    }
                }
            }
        }
    }
}
.social-media{
    ul{
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        li{
            padding: 0 8px;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
            @include media-breakpoint-down(md) {
                padding: 0 4px;
            }
            a{
                display: block;
                width:38px;
                height:38px;
                border-radius:9px;
                background-color: $white;
                color: $grey-dart-100;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover{
                    background-color: $primary;
                    color: $white;
                }
                @include media-breakpoint-down(md) {
                    width:30px;
                    height:30px;
                    border-radius:5px;
                }
            }
        }
    }
}