/* About Us */
.about-content{
	padding: 150px 0;
	background-color: $light-gray;
	@include media-breakpoint-down(lg) {
		padding: 50px 0;
	}
	.content-box{
		background-color:$white;
		padding: 70px;
		@include media-breakpoint-down(lg) {
			padding: 40px 30px;
		}
		@include media-breakpoint-down(lg) {
			padding: 30px 20px;
		}
	}
	.content-column {
		position: relative;
		z-index: 9;
	}
	.image-column{
		position: relative;
		.inner-column {
			position: relative;
			margin-right: -100px;
			padding-right: 100px;
			text-align: right;
			height: 100%;
			@include media-breakpoint-down(md) {
				margin-right: 0;
				padding-right: 0;
				text-align: center;
				height: auto;
				width: 100%;
			}
			&:after{
				position: absolute;
				left: 0;
				top: 100px;
				height: 530px;
				width: 700px;
				border: 13px solid $secondary;
				content: "";
				z-index: 1;
				@include media-breakpoint-down(md) {
					content: none;
				}
			}
			.title-box {
				position: absolute;
				top: 300px;
				left: 70px;
				width: auto;
				text-align: left;
				z-index: 9;
				background-color: rgba(255, 255, 255, 0.8);
				padding: 30px;
				@include media-breakpoint-down(md) {
					left: 0;
					top: auto;
					bottom: 0;
				}
				h2 {
					position: relative;
					display: block;
					font-size: 72px;
					line-height: 1;
					color: $secondary;
					font-weight: 700;
					@include media-breakpoint-down(md) {
						font-size: 52px;
					}
					@include media-breakpoint-down(md) {
						font-size: 40px;
					}
				}
				.small-title {
					position: relative;
					display: block;
					font-size: 30px;
					line-height: 1.2em;
					color: $grey-semi-dark;
					font-weight: 700;
					margin-top: 10px;
					@include media-breakpoint-down(md) {
						font-size: 24px;
					}
					@include media-breakpoint-down(md) {
						font-size: 20px;
					}
				}
				
			}
			.image-box {
				position: relative;
				z-index: 2;
				height: 100%;
			}
			img{
				display: inline-block;
				max-width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
}
/* Mission Vision */
.mission-vision-section{
	.left-content{
		.content-col{
			order: 1;
		}
		.img-col{
			order: 2;
		}
	}
	.right-content{
		.content-col{
			order: 2;
			@include media-breakpoint-down(md) {
				order: 1;
			}
		}
		.img-col{
			order: 1;
			@include media-breakpoint-down(md) {
				order: 2;
			}
		}
	}
	.content-col{
		display:flex;
		align-items: center;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.content-xs{
		margin: 5vw 5vw;
		@include media-breakpoint-down(sm) {
			margin: 40px 15px;
		}
		.sub-heading{
			font-size:1rem;
			line-height:1.2;
			font-weight: $bold;
			color: $primary;
			margin-bottom: 15px;
			display: block;
			margin-bottom: 15px;
		}
		h3{
			line-height:1.4;
		}
		p{
			text-align: justify;
		}
		.mobile-img{
			float: right;
			width: 52%;
			padding: 0 0 15px 15px;
		}
	}
	.img-col{
		img{
			width: 100%;
			height:100%;
			object-fit: cover;
			object-position: center;
		}
	}
}

/* Certifications Section*/
.certificate-section{
	padding: 100px 0 100px;
	// background-position: center;
	// background-repeat: no-repeat;
	// background-size:cover;
	@include media-breakpoint-down(lg) {
		padding: 50px 0;
	}
	.certificate-row{
		margin-bottom: -30px;
		.certificate-col{
			margin-bottom: 30px;
		}
	}
	.section-heading {
		margin-bottom: 50px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 30px;
		}
	}
	.certificate-block{
		border-radius: 10px;
		border: 3px solid $light-gray;
		background-color: $white;
		padding: 20px;
		text-align: center;
		transition: all 0.5s ease;
		&:hover{
			box-shadow: 0 15px 50px rgba(0, 0, 0, 0.16);
			transition: all 0.5s ease;
			border-color: transparent;
		}
	}
}